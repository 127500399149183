body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/res/logo512.png');
  background-position: left top;
  background-repeat: no-repeat;
  background-blend-mode: soft-light;
  background-size: 100rem;
}

a:hover {
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

code.hljs {
  display: inline;
  padding: 0 0.5rem;
}

pre > code.hljs {
  display: block;
  padding: 0.5rem;
}

.simple-link, .simple-link:active, .simple-link:hover {
  text-decoration: none;
}

.blog-context {
  line-height: 1.66rem !important;
  color: #555;
  h1 {
    width: 100%;
    margin-top: 2.5rem;
    padding: 6px 4px 4px 4px;
    border-left: 10px solid #8c8;
    border-bottom: 1px solid #8c8;
  }
  h2 {
    color: #666;
    width: 100%;
    margin-top: 2.5rem;
    padding: 6px 4px 4px 4px;
    border-left: 10px solid #8c8;
    border-bottom: 1px solid #8c8;
  }
  div {
    margin: 0.7rem 0;
  }
  pre {
    margin: 0;
    padding: 0;
    background-color: #282c34;
    overflow: auto;
  }
  img {
    max-width: 100%;
  }
  a {
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
  }
}
